<template>
	<div class="order_wrap">
		<v-container class="order_home px-6">
			<div class="txt_box mt-8">
				<p>
					<span class="small">안녕하세요. 코웨이닷컴입니다.</span>
					<span class="impact">전자서명이 완료되었습니다.</span>
					<span class="txt_body1"
						>전자서명 완료 후 주문정보는 고객님의 휴대폰 번호로 안내됩니다.<br />
						주문 상세내역은 코웨이닷컴 마이코웨이>쇼핑관리>주문내역에서 확인하실
						수 있습니다.<br />
						설치일 변경이 필요하신 경우 고객센터(1588-5200)를 통해 문의해주세요.
					</span>
				</p>
			</div>
		</v-container>
	</div>
</template>

<script>
export default {
	components: {},
	data() {
		return {}
	},
	mounted() {
		this.$nextTick(() => {
			document.title = '코웨이닷컴'
		})
	},
	methods: {
		goNext() {
			this.$router.push({ name: 'ec-cust-complete-homepage2' })
		}
	}
}
</script>

<style scoped lang="scss"></style>
